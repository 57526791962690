<template>
  <v-container class="text-center mt-10 pa-5">
    <v-icon size="100" color="warning">mdi-alert-octagram</v-icon>
    <h1 class="text-h4 font-weight-bold mt-5">404</h1>
    <h2>Halaman Tidak Ditemukan</h2>
    <p class="text-subtitle1 mt-2">Maaf, halaman yang Anda cari tidak ditemukan. Silakan kembali ke beranda.</p>
  </v-container>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
